<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->

							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>We do <strong>NOT</strong> provide support for catch up or recording.</p>
								<p><br /></p>
								<p>{{ $store.state.brandInfo.displayName }} <strong>has a better</strong> <strong>solution</strong> than playing a channel without your control:</p>
								<p><br /></p>
								<p>
									- <strong>We provide</strong> a library of <strong>over 10,000 Movies, and 1,200 Full TV Shows</strong> (With their whole seasons and episodes),
									and it keeps growing all the time.
								</p>
								<p><br /></p>
								<p>
									- The <strong>VOD content</strong> of ours is t<strong>he biggest you have ever seen</strong>, and you <strong>have subtitles</strong> for
									<strong>multiple langues!!</strong>
								</p>
								<p><br /></p>
								<p>
									- All our VOD content uses <strong>high quality Audio and Video</strong>, all our movies are
									<strong>H264 Full HD, with Dolby Digital AC3, with 5.1</strong>.
								</p>
								<p><br /></p>
								<p>
									- <strong>All </strong>our <strong>VOD content is searchable</strong>, and has info of the content <strong>with pictures and trailer</strong>.
								</p>
								<p><br /></p>
								<p>
									- All our VOD content <strong>you </strong>are <strong>able to seek forward and backward</strong>, and <strong>choose </strong>the
									<strong>episode or season</strong> you desire.
								</p>
								<p><br /></p>
								<p><br /></p>
								<p>
									And <strong>this is the reason</strong> why with us <strong>you will never want</strong> to <strong>go back to 24/7 catch up channels</strong>.
								</p>
								<p><br /></p>
								<p><br /></p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'doYouProvide247ChannelsCatchUp',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
